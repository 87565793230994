
import goat from './images/goat.gif';

export function App() {
  
  return (
    
    <div className="flex items-center min-h-screen bg-[#386249]">
    
      <div className="max-w-7xl mx-auto">
    
        <div className="md:flex items-center md:px-4">
    
          <div className="pl-5">
    
            <img className="block" src={ goat } alt="the surprised goat" draggable="false"/>
            
          </div>
          
          <div className="min-w-max md:pr-14 font-invulnerable text-center md:text-left">
          
            <p className="font-bold text-6xl md:text-7xl lg:text-8xl text-[#fce4ae] drop-shadow-md"
            
            style={ { WebkitTextStroke: '3px #000', textShadow: '3px 3px 0 #000' } }>
            
              <span className="tracking-tighter">What is<br />this</span> ???
              
            </p>
            
            <p className="mt-4 text-xl lg:text-3xl drop-shadow-sm">
            
              <strong>No idea.</strong> More to come.
              
            </p>
              
          </div>
          
        </div>
        
      </div>
      
    </div>
    
  );
  
}
